import React from 'react';
import classNames from 'classnames';
import { renderAsset } from '.';

import styles from './side-by-side.module.css';

export default ({ entries, className, ...props }) => {
  const numEntries = entries ? entries.length : 0;
  if (!numEntries) {
    return;
  }

  return (
    <div className={classNames(styles.container, className)} {...props}>
      {entries.map(asset => (
        <React.Fragment key={asset.id}>
          {renderAsset(asset, {
            className: styles.image,
            style: { flex: `${asset.fluid.aspectRatio} 0 auto` },
          })}
        </React.Fragment>
      ))}
    </div>
  );
};
