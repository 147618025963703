import React, { useState, useCallback } from 'react';
import classNames from 'classnames';
import { renderToReact, renderAsset } from '.';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

import styles from './gallery-with-captions.module.css';

export default ({ entries, renderOptions, className, ...props }) => {
  const numEntries = entries ? entries.length : 0;
  const [activeIndex, setActiveIndex] = useState(0);
  const goToPrevious = useCallback(() => {
    setActiveIndex((activeIndex - 1 + numEntries) % numEntries);
  }, [numEntries, activeIndex]);
  const goToNext = useCallback(() => {
    setActiveIndex((activeIndex + 1) % numEntries);
  }, [numEntries, activeIndex]);

  if (!numEntries) {
    return;
  }

  return (
    <div className={classNames(styles.container, className)} {...props}>
      <button
        className={classNames(styles.arrow, styles.left)}
        onClick={goToPrevious}
      >
        <FaChevronLeft />
      </button>
      <div className={styles.main}>
        {entries.map(({ id, asset, caption }, index) => (
          <div
            key={id}
            className={classNames({
              [styles.section]: true,
              [styles.hidden]: index !== activeIndex,
            })}
          >
            {renderAsset(asset, { className: styles.asset, loading: 'auto' })}
            {renderToReact(caption, {}, renderOptions)}
          </div>
        ))}
      </div>
      <button
        className={classNames(styles.arrow, styles.right)}
        onClick={goToNext}
      >
        <FaChevronRight />
      </button>
    </div>
  );
};
