import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import styles from './index.module.css';

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 200, maxHeight: 200) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      header: contentfulSiteHeader {
        navbar {
          ... on Node {
            ... on ContentfulProjectsPage {
              id
              name
              slug
            }
            ... on ContentfulRichTextPage {
              id
              title
              slug
            }
            ... on ContentfulSubmenu {
              id
              name
              entries {
                ... on Node {
                  ... on ContentfulProjectsPage {
                    id
                    name
                    slug
                  }
                  ... on ContentfulRichTextPage {
                    id
                    title
                    slug
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  const logoFluid = data.logo.childImageSharp.fluid;
  const navbar = data.header.navbar;

  return (
    <>
      <nav className={styles.nav}>
        <div className={styles.logoContainer}>
          <Link to="/" className={styles.logoLink}>
            <Img fluid={logoFluid} alt="Logo" className={styles.logo} />
          </Link>
        </div>
        <ul className={styles.horizontal}>
          {navbar.map(entry => {
            if (entry.entries) {
              return (
                <li key={entry.id}>
                  <button className={styles.link}>{entry.name}</button>
                  <ul className={styles.submenu}>
                    {entry.entries.map(entry => (
                      <li key={entry.id}>
                        <Link to={entry.slug} activeClassName={styles.active}>
                          {entry.name || entry.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>
              );
            }
            return (
              <li key={entry.id}>
                <Link to={entry.slug} activeClassName={styles.active}>
                  {entry.name || entry.title}
                </Link>
              </li>
            );
          })}
        </ul>
      </nav>
    </>
  );
};

export default Header;
