import React, { useState, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { renderAsset } from '.';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

import styles from './gallery.module.css';

export default ({ entries, className, ...props }) => {
  const numEntries = entries ? entries.length : 0;
  const [activeIndex, setActiveIndex] = useState(0);
  const goToPrevious = useCallback(() => {
    setActiveIndex((activeIndex - 1 + numEntries) % numEntries);
  }, [numEntries, activeIndex]);
  const goToNext = useCallback(() => {
    setActiveIndex((activeIndex + 1) % numEntries);
  }, [numEntries, activeIndex]);
  const jumpTo = useMemo(
    () => [...Array(numEntries)].map((_, i) => () => setActiveIndex(i)),
    [numEntries],
  );

  if (!numEntries) {
    return;
  }

  return (
    <div className={classNames(styles.container, className)} {...props}>
      <button
        className={classNames(styles.arrow, styles.left)}
        onClick={goToPrevious}
      >
        <FaChevronLeft />
      </button>
      <div className={styles.main}>
        {entries.map((asset, index) => (
          <div
            key={asset.id}
            className={classNames({
              [styles.section]: true,
              [styles.hidden]: index !== activeIndex,
            })}
          >
            {renderAsset(asset, { className: styles.asset, loading: 'auto' })}
            <div className={styles.progress}>
              {entries.map((_, dotIndex) => (
                <button
                  key={dotIndex}
                  className={classNames({
                    [styles.active]: index === dotIndex,
                  })}
                  onClick={jumpTo[dotIndex]}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
      <button
        className={classNames(styles.arrow, styles.right)}
        onClick={goToNext}
      >
        <FaChevronRight />
      </button>
    </div>
  );
};
