/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core';
import classNames from 'classnames';

import Header from '../header';
import './index.css';
import styles from './index.module.css';

const muiTheme = createMuiTheme({
  typography: {
    fontFamily: ['inherit'],
    button: {
      textTransform: null,
      fontSize: null,
    },
  },
});

const Layout = ({ children, flexbox }) => {
  return (
    <ThemeProvider theme={muiTheme}>
      <div
        className={classNames({
          [styles.layout]: true,
          [styles.flexbox]: flexbox,
        })}
      >
        <Header />
        <div className={styles.mainContainer}>
          <main>{children}</main>
        </div>
      </div>
    </ThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  flexbox: PropTypes.bool,
};

Layout.defaultProps = {
  flexbox: false,
};

export default Layout;
