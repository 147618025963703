import React from 'react';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Img from 'gatsby-image';
import classNames from 'classnames';

import Gallery from './gallery';
import GalleryWithCaptions from './gallery-with-captions';
import SideBySide from './side-by-side';

import styles from './index.module.css';

const keyByNcid = (nodes, node) =>
  node.fields && node.fields.normalizedContentfulId
    ? { ...nodes, [node.fields.normalizedContentfulId]: node }
    : nodes;

export const renderToReact = (
  richTextNode,
  { className, ...props } = {},
  { renderMark = {}, renderNode = {}, ...options } = {},
) => {
  if (!richTextNode) {
    return;
  }

  const embeds = (richTextNode.embeds || []).reduce(keyByNcid, {});
  const renderLink = link => {
    const id = link.data.target.sys.id;
    const embed = embeds[id];
    if (!embed) {
      console.warn('Failed to render missing embed with ID', id);
      return;
    }
    return renderEmbed(embed, {}, renderOptions);
  };
  const renderOptions = {
    renderMark: {
      [MARKS.BOLD]: text => <strong>{text}</strong>,
      [MARKS.ITALIC]: text => <em>{text}</em>,
      ...renderMark,
    },
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: renderLink,
      [BLOCKS.EMBEDDED_ENTRY]: renderLink,
      ...renderNode,
    },
    ...options,
  };

  return (
    <div className={classNames(styles.richText, className)} {...props}>
      {documentToReactComponents(richTextNode.json, renderOptions)}
    </div>
  );
};

export const renderEmbed = (embed, props, renderOptions) => {
  if (!embed) {
    return;
  }

  const type = embed.internal.type;
  switch (type) {
    case 'ContentfulAsset':
      return renderAsset(embed, props);
    case 'ContentfulLayout':
      return renderLayout(embed, props, renderOptions);
    case 'ContentfulGalleryWidget':
      return renderGallery(embed, props);
    case 'ContentfulGalleryWithCaptionsWidget':
      return renderGalleryWithCaptions(embed, props, renderOptions);
    case 'ContentfulSideBySideWidget':
      return renderSideBySide(embed, props);
    default:
      console.warn('Failed to render embed of unknown type:', type);
      return;
  }
};

export const renderAsset = (asset, { className, loading, ...props } = {}) => {
  if (!asset) {
    return;
  }

  const { file, fluid } = asset;
  const { contentType, url } = file;

  if (contentType.startsWith('video/')) {
    return (
      <div className={classNames(styles.videoContainer, className)} {...props}>
        <video controls={true} className={styles.asset}>
          <source src={url} type={contentType} />
        </video>
      </div>
    );
  }

  if (contentType.startsWith('image/')) {
    return (
      <div className={classNames(styles.imageContainer, className)} {...props}>
        <Img
          fluid={fluid}
          className={styles.asset}
          imgStyle={{ objectFit: 'contain' }}
          loading={loading}
        />
      </div>
    );
  }

  console.warn('Failed to render asset of unknown content type:', contentType);
};

export const renderLayout = (layout, props, renderOptions) => {
  if (!layout) {
    return;
  }

  const styles = (layout.styles ? layout.styles.fields.array : []).reduce(
    (rules, { key, value }) => ({
      ...rules,
      [key]: value,
    }),
    {},
  );
  return (
    <div style={styles} {...props}>
      {layout.body &&
        documentToReactComponents(layout.body.json, renderOptions)}
    </div>
  );
};

export const renderGallery = (gallery, props = {}) => {
  if (!gallery) {
    return;
  }

  return <Gallery {...props} entries={gallery.entries} />;
};

export const renderGalleryWithCaptions = (
  gallery,
  props = {},
  renderOptions,
) => {
  if (!gallery) {
    return;
  }

  return (
    <GalleryWithCaptions
      {...props}
      entries={gallery.entries}
      renderOptions={renderOptions}
    />
  );
};

export const renderSideBySide = (sideBySide, props = {}) => {
  if (!sideBySide) {
    return;
  }

  return <SideBySide {...props} entries={sideBySide.entries} />;
};
